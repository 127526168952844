<template>
	<v-row no-gutters>
		<v-col cols="12">
      <iframe
        title="BI Pricing"
        style="height:80vh; width:100%"
        src="https://app.powerbi.com/view?r=eyJrIjoiMGM4Y2NkYTItMDg1Yy00ODZlLWJhMzQtNzU5NzQzYTFmOGY3IiwidCI6ImYxZGJlOWZhLWZkNTktNDMzZS04Y2RjLTI4ODRmZDgyYmRhNSJ9&pageName=ReportSectiona08bc7e4b49e2cffedb4"
        frameborder="0"
        allowfullscreen="true"
      ></iframe>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "PricingBI",
	components: {},
	data: () => ({
		tab: 0,
	}),
	computed: {},
	methods: {
		async init() {},
	},
	created() {
		this.init();
	},
};
</script>